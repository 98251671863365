//react
import React from "react";

//routing
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserLoggedOut } from 'redux/actions';

//firebase
import { auth } from 'assets/firebase';

class LogoutPage extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
          signedOut: false
        };
    }
    
    componentDidMount() {
        
        auth.signOut().then((success) => {
            if(success) {
                this.props.onUserLoggedOut();
                this.setState({signedOut: true});
            }
        });
    }

    render() {

        if (this.state.signedOut === true) {
            return <Redirect to='/' />
        }

        return (
            <div>
                Signing out ...
            </div>
        )
    }
}


const mapStateToProps = state => {
    return { user: state.user };
}
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ onUserLoggedOut: UserLoggedOut }, dispatch);   
}
  
LogoutPage = connect(mapStateToProps, mapDispatchToProps)(LogoutPage);  
export default LogoutPage;